const WhyChooseUsComponent = () => {
    return (  
        <div className="container">
            <div className="row section">
            <div className="title">
                <div className="lines">
                    <div className="short-line left"></div>
                    <div className="long-line left"></div>
                </div>
                <h3 className="section-title">Why Choose Us</h3>
                <div className="lines">
                    <div className="short-line right"></div>
                    <div className="long-line right"></div>
                </div>
                </div>
                    <p className="section-intro">Why people choose us...</p>
                </div>
            <div className="row">
                <div className="col-sm-12 col-md-4">
                    <div className="choose-item-outer">
                        <div className="choose-item-inner">
                            <img className="icon" src="./timely-icon.svg" alt="" />
                            <p className="description">Timely SMS Delivery</p>
                        </div>
                        <p className="more-description">Our Bulk SMS service ensures that your messages are delivered swiftly and reliably. You can trust us to send your SMSs promptly backed by 24/7 customer support.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <div className="choose-item-outer">
                        <div className="choose-item-inner">
                            <img className="icon" src="./secure-icon.svg" alt="" />
                            <p className="description">Secure Message Transmission</p>
                        </div>
                        <p className="more-description">We prioritize the security of your messages, guaranteeing a safe and confidential transfer of your SMS content.</p>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <div className="choose-item-outer">
                        <div className="choose-item-inner">
                            <img className="icon" src="./borderless-icon.svg" alt="" />
                            <p className="description">Great SMS Reach</p>
                        </div>
                        <p className="more-description">Experience borderless SMS communication, enabling you to send messages internationally without the limitations of traditional borders. Enjoy fast and secure SMS delivery accross the globe.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default WhyChooseUsComponent;