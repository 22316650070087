const AboutUsComponent = () => {
    return ( 
        <div className="container-fluid section-section">
            <div className="row section">
                <div className="title">
                    <div className="lines">
                        <div className="short-line left"></div>
                        <div className="long-line left"></div>
                    </div>
                    <h3 className="section-title">About Us</h3>
                    <div className="lines">
                        <div className="short-line right"></div>
                        <div className="long-line right"></div>
                    </div>
                </div>
            </div>
            <div className="row aboutus-ex">
                <div className="col-sm">
                    <img src="./about.jpg" alt="about us" className="aboutus-img" />
                </div>
                <div className="col-sm aboutus-text">
                <h4 className="heading">#1 Digital Solution With <span className="blue-text">2 years</span> of Experience</h4>
                    <p className="paragraph">Kimipay is a leading e-payment platform dedicated to simplifying 
                    and securing international fund transfers across 16 major currencies. 
                    Experience convienience and security with our SMS services, and stay tuned for more 
                    exciting enhancements!</p>
                </div>
            </div>
        </div>
    );
}

export default AboutUsComponent;